<script>
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import DropdownUser from "./DropdownUser";

export default {
  components: {
    DarkToggler,
    DropdownUser
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  }
};
</script>

<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <DropdownUser />
    </b-navbar-nav>
  </div>
</template>
