<script>
import { LOGOUT } from "@/store/constants/actions.type";
import ApiService from "@services/api.service";
import { mapState } from "vuex";

export default {
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      loader: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  },
  methods: {
    changePassword() {
      if (this.oldPassword === this.newPassword) {
        this.showToast("error", "Old Password cannot be used again");
      } else if (this.newPassword !== this.confirmPassword) {
        this.showToast("error", "Please confirm password correctly");
      } else {
        this.loader = true;
        let payload = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        };
        ApiService.patch("/me", payload)
          .then(() => {
            this.showToast("success", "Change Password Success");
            this.$refs["password"].hide();
            this.loader = false;
          })
          .error(err => {
            this.showToast("error", err);
            this.loader = false;
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    clearModal() {
      this.oldPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;
    },
    logout() {
      this.$store.dispatch(LOGOUT).catch(err => {
        this.showToast("error", err);
      });
    }
  }
};
</script>

<template>
  <div>
    <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
      <template #button-content>
        <div v-if="user" class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">{{ user.displayedName }}</p>
          <span class="user-status">{{ user.username }}</span>
        </div>
        <b-avatar
          size="40"
          variant="light-primary"
          badge
          :src="require('@/assets/images/avatars/13-small.png')"
          class="badge-minimal"
          badge-variant="success"
        />
      </template>

      <b-dropdown-item link-class="d-flex align-items-center" @click="$bvModal.show('password')">
        <feather-icon size="16" icon="KeyIcon" class="mr-50" />
        <span>Password</span>
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-item-button link-class="d-flex align-items-center" @click="logout">
        <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
        <span>Logout</span>
      </b-dropdown-item-button>
    </b-nav-item-dropdown>

    <b-modal id="password" ref="password" title="Change Password" hide-footer @hidden="clearModal">
      <b-row>
        <b-col>
          <b-form-group label="Old Password">
            <b-input v-model="oldPassword" type="password" class="form-control" />
          </b-form-group>

          <b-form-group label="New Password">
            <b-input v-model="newPassword" type="password" class="form-control" />
          </b-form-group>

          <b-form-group label="Confirm Password">
            <b-input v-model="confirmPassword" type="password" class="form-control" />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex">
        <b-btn class="mr-auto" variant="danger" :disabled="loader" @click="$bvModal.hide('password')"> Cancel </b-btn>
        <b-btn class="ml-2" variant="success" :disabled="loader" @click.stop="changePassword"> Submit </b-btn>
      </div>
    </b-modal>
  </div>
</template>
