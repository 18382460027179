export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    role: [0, 1, 2, 3]
  },

  {
    header: "Management",
    role: [0]
  },
  {
    title: "Upload Leads",
    route: "management-leads",
    icon: "UploadIcon",
    role: [0]
  },
  {
    title: "User Management",
    route: "management-sales-account",
    icon: "UsersIcon",
    role: [0]
  },
  {
    title: "Score Card",
    route: "management-score-card",
    icon: "AwardIcon",
    role: [0]
  },
  {
    title: "Notification",
    route: "management-notification",
    icon: "BellIcon",
    role: [0]
  },

  {
    header: "CMS",
    role: [0, 1, 2, 3]
  },
  {
    title: "News",
    route: "cms-news",
    icon: "TvIcon",
    role: [0, 1, 2, 3],
    children: [
      {
        title: "Group",
        route: "cms-news-group"
      },
      {
        title: "Item",
        route: "cms-news"
      }
    ]
  },
  {
    title: "Learning",
    route: "learning",
    icon: "MonitorIcon",
    role: [0, 1, 2, 3],
    children: [
      {
        title: "Group",
        route: "cms-learning-category"
      },
      {
        title: "Item",
        route: "cms-learning-item"
      }
    ]
  },
  {
    title: "Flyer",
    route: "cms-flyer-item",
    icon: "LayersIcon",
    role: [0, 1, 2, 3]
  },
  {
    header: "Report",
    role: [0, 1, 2]
  },
  {
    title: "Leads Plain",
    route: "report-leads-plain",
    icon: "MonitorIcon",
    role: [0, 1, 2]
  },
  {
    title: "Attendance",
    route: "report-attendance",
    icon: "ClipboardIcon",
    role: [0, 1, 2]
  },
  {
    title: "Leave",
    route: "report-leave",
    icon: "BookmarkIcon",
    role: [0, 1, 2]
  },

  {
    header: "Parameter",
    role: [0]
  },

  {
    title: "Activity",
    icon: "BookOpenIcon",
    role: [0],
    children: [
      {
        title: "Result ",
        route: "parameter-activity-result"
      },
      {
        title: "Type",
        route: "parameter-activity-type"
      },
      {
        title: "Leads Type",
        route: "parameter-leads"
      }
    ]
  },
  {
    title: "Product",
    icon: "ArchiveIcon",
    role: [0],
    children: [
      {
        title: "Category",
        route: "parameter-product-category"
      },
      {
        title: "Item",
        route: "parameter-product"
      }
    ]
  },
  {
    title: "Leave Type",
    route: "parameter-leave-type",
    icon: "EyeOffIcon",
    role: [0]
  },
  {
    title: "Program",
    route: "parameter-program",
    icon: "DatabaseIcon",
    role: [0]
  },

  // activity type
  // leave type
  // product => product category & product item

  { header: "Configuration", role: [0] },
  {
    title: "Admin Account",
    route: "config-admin-account",
    icon: "UserPlusIcon",
    role: [0]
  }
];
